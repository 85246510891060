@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("../fonts/open-sans/open-sans-cyrillic-ext-300-normal.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-300-normal.woff") format("woff");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("../fonts/open-sans/open-sans-cyrillic-300-normal.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-300-normal.woff") format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("../fonts/open-sans/open-sans-greek-ext-300-normal.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-300-normal.woff") format("woff");
  unicode-range: U+1F00-1FFF;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("../fonts/open-sans/open-sans-greek-300-normal.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-300-normal.woff") format("woff");
  unicode-range: U+0370-03FF;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("../fonts/open-sans/open-sans-vietnamese-300-normal.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-300-normal.woff") format("woff");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("../fonts/open-sans/open-sans-latin-ext-300-normal.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-300-normal.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("../fonts/open-sans/open-sans-latin-300-normal.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-300-normal.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src: url("../fonts/open-sans/open-sans-cyrillic-ext-300-italic.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-300-italic.woff") format("woff");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src: url("../fonts/open-sans/open-sans-cyrillic-300-italic.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-300-italic.woff") format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src: url("../fonts/open-sans/open-sans-greek-ext-300-italic.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-300-italic.woff") format("woff");
  unicode-range: U+1F00-1FFF;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src: url("../fonts/open-sans/open-sans-greek-300-italic.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-300-italic.woff") format("woff");
  unicode-range: U+0370-03FF;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src: url("../fonts/open-sans/open-sans-vietnamese-300-italic.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-300-italic.woff") format("woff");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src: url("../fonts/open-sans/open-sans-latin-ext-300-italic.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-300-italic.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src: url("../fonts/open-sans/open-sans-latin-300-italic.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-300-italic.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("../fonts/open-sans/open-sans-cyrillic-ext-400-normal.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-400-normal.woff") format("woff");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("../fonts/open-sans/open-sans-cyrillic-400-normal.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-400-normal.woff") format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("../fonts/open-sans/open-sans-greek-ext-400-normal.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-400-normal.woff") format("woff");
  unicode-range: U+1F00-1FFF;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("../fonts/open-sans/open-sans-greek-400-normal.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-400-normal.woff") format("woff");
  unicode-range: U+0370-03FF;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("../fonts/open-sans/open-sans-vietnamese-400-normal.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-400-normal.woff") format("woff");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("../fonts/open-sans/open-sans-latin-ext-400-normal.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-400-normal.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("../fonts/open-sans/open-sans-latin-400-normal.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-400-normal.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("../fonts/open-sans/open-sans-cyrillic-ext-400-italic.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-400-italic.woff") format("woff");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("../fonts/open-sans/open-sans-cyrillic-400-italic.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-400-italic.woff") format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("../fonts/open-sans/open-sans-greek-ext-400-italic.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-400-italic.woff") format("woff");
  unicode-range: U+1F00-1FFF;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("../fonts/open-sans/open-sans-greek-400-italic.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-400-italic.woff") format("woff");
  unicode-range: U+0370-03FF;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("../fonts/open-sans/open-sans-vietnamese-400-italic.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-400-italic.woff") format("woff");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("../fonts/open-sans/open-sans-latin-ext-400-italic.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-400-italic.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("../fonts/open-sans/open-sans-latin-400-italic.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-400-italic.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("../fonts/open-sans/open-sans-cyrillic-ext-600-normal.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-600-normal.woff") format("woff");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("../fonts/open-sans/open-sans-cyrillic-600-normal.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-600-normal.woff") format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("../fonts/open-sans/open-sans-greek-ext-600-normal.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-600-normal.woff") format("woff");
  unicode-range: U+1F00-1FFF;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("../fonts/open-sans/open-sans-greek-600-normal.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-600-normal.woff") format("woff");
  unicode-range: U+0370-03FF;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("../fonts/open-sans/open-sans-vietnamese-600-normal.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-600-normal.woff") format("woff");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("../fonts/open-sans/open-sans-latin-ext-600-normal.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-600-normal.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("../fonts/open-sans/open-sans-latin-600-normal.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-600-normal.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: url("../fonts/open-sans/open-sans-cyrillic-ext-600-italic.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-600-italic.woff") format("woff");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: url("../fonts/open-sans/open-sans-cyrillic-600-italic.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-600-italic.woff") format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: url("../fonts/open-sans/open-sans-greek-ext-600-italic.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-600-italic.woff") format("woff");
  unicode-range: U+1F00-1FFF;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: url("../fonts/open-sans/open-sans-greek-600-italic.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-600-italic.woff") format("woff");
  unicode-range: U+0370-03FF;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: url("../fonts/open-sans/open-sans-vietnamese-600-italic.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-600-italic.woff") format("woff");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: url("../fonts/open-sans/open-sans-latin-ext-600-italic.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-600-italic.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: url("../fonts/open-sans/open-sans-latin-600-italic.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-600-italic.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("../fonts/open-sans/open-sans-cyrillic-ext-700-normal.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-700-normal.woff") format("woff");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("../fonts/open-sans/open-sans-cyrillic-700-normal.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-700-normal.woff") format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("../fonts/open-sans/open-sans-greek-ext-700-normal.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-700-normal.woff") format("woff");
  unicode-range: U+1F00-1FFF;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("../fonts/open-sans/open-sans-greek-700-normal.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-700-normal.woff") format("woff");
  unicode-range: U+0370-03FF;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("../fonts/open-sans/open-sans-vietnamese-700-normal.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-700-normal.woff") format("woff");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("../fonts/open-sans/open-sans-latin-ext-700-normal.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-700-normal.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("../fonts/open-sans/open-sans-latin-700-normal.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-700-normal.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url("../fonts/open-sans/open-sans-cyrillic-ext-700-italic.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-700-italic.woff") format("woff");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url("../fonts/open-sans/open-sans-cyrillic-700-italic.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-700-italic.woff") format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url("../fonts/open-sans/open-sans-greek-ext-700-italic.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-700-italic.woff") format("woff");
  unicode-range: U+1F00-1FFF;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url("../fonts/open-sans/open-sans-greek-700-italic.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-700-italic.woff") format("woff");
  unicode-range: U+0370-03FF;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url("../fonts/open-sans/open-sans-vietnamese-700-italic.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-700-italic.woff") format("woff");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url("../fonts/open-sans/open-sans-latin-ext-700-italic.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-700-italic.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url("../fonts/open-sans/open-sans-latin-700-italic.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-700-italic.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url("../fonts/open-sans/open-sans-cyrillic-ext-800-normal.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-800-normal.woff") format("woff");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url("../fonts/open-sans/open-sans-cyrillic-800-normal.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-800-normal.woff") format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url("../fonts/open-sans/open-sans-greek-ext-800-normal.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-800-normal.woff") format("woff");
  unicode-range: U+1F00-1FFF;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url("../fonts/open-sans/open-sans-greek-800-normal.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-800-normal.woff") format("woff");
  unicode-range: U+0370-03FF;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url("../fonts/open-sans/open-sans-vietnamese-800-normal.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-800-normal.woff") format("woff");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url("../fonts/open-sans/open-sans-latin-ext-800-normal.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-800-normal.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url("../fonts/open-sans/open-sans-latin-800-normal.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-800-normal.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src: url("../fonts/open-sans/open-sans-cyrillic-ext-800-italic.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-800-italic.woff") format("woff");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src: url("../fonts/open-sans/open-sans-cyrillic-800-italic.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-800-italic.woff") format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src: url("../fonts/open-sans/open-sans-greek-ext-800-italic.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-800-italic.woff") format("woff");
  unicode-range: U+1F00-1FFF;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src: url("../fonts/open-sans/open-sans-greek-800-italic.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-800-italic.woff") format("woff");
  unicode-range: U+0370-03FF;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src: url("../fonts/open-sans/open-sans-vietnamese-800-italic.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-800-italic.woff") format("woff");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src: url("../fonts/open-sans/open-sans-latin-ext-800-italic.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-800-italic.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src: url("../fonts/open-sans/open-sans-latin-800-italic.woff2") format("woff2"), url("../fonts/open-sans/open-sans-all-800-italic.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("../fonts/playfair-display/playfair-display-cyrillic-400-normal.woff2") format("woff2"), url("../fonts/playfair-display/playfair-display-all-400-normal.woff") format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("../fonts/playfair-display/playfair-display-vietnamese-400-normal.woff2") format("woff2"), url("../fonts/playfair-display/playfair-display-all-400-normal.woff") format("woff");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("../fonts/playfair-display/playfair-display-latin-ext-400-normal.woff2") format("woff2"), url("../fonts/playfair-display/playfair-display-all-400-normal.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("../fonts/playfair-display/playfair-display-latin-400-normal.woff2") format("woff2"), url("../fonts/playfair-display/playfair-display-all-400-normal.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Playfair Display";
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("../fonts/playfair-display/playfair-display-cyrillic-400-italic.woff2") format("woff2"), url("../fonts/playfair-display/playfair-display-all-400-italic.woff") format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: "Playfair Display";
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("../fonts/playfair-display/playfair-display-vietnamese-400-italic.woff2") format("woff2"), url("../fonts/playfair-display/playfair-display-all-400-italic.woff") format("woff");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Playfair Display";
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("../fonts/playfair-display/playfair-display-latin-ext-400-italic.woff2") format("woff2"), url("../fonts/playfair-display/playfair-display-all-400-italic.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Playfair Display";
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("../fonts/playfair-display/playfair-display-latin-400-italic.woff2") format("woff2"), url("../fonts/playfair-display/playfair-display-all-400-italic.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("../fonts/playfair-display/playfair-display-cyrillic-700-normal.woff2") format("woff2"), url("../fonts/playfair-display/playfair-display-all-700-normal.woff") format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("../fonts/playfair-display/playfair-display-vietnamese-700-normal.woff2") format("woff2"), url("../fonts/playfair-display/playfair-display-all-700-normal.woff") format("woff");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("../fonts/playfair-display/playfair-display-latin-ext-700-normal.woff2") format("woff2"), url("../fonts/playfair-display/playfair-display-all-700-normal.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("../fonts/playfair-display/playfair-display-latin-700-normal.woff2") format("woff2"), url("../fonts/playfair-display/playfair-display-all-700-normal.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Playfair Display";
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url("../fonts/playfair-display/playfair-display-cyrillic-700-italic.woff2") format("woff2"), url("../fonts/playfair-display/playfair-display-all-700-italic.woff") format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: "Playfair Display";
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url("../fonts/playfair-display/playfair-display-vietnamese-700-italic.woff2") format("woff2"), url("../fonts/playfair-display/playfair-display-all-700-italic.woff") format("woff");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Playfair Display";
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url("../fonts/playfair-display/playfair-display-latin-ext-700-italic.woff2") format("woff2"), url("../fonts/playfair-display/playfair-display-all-700-italic.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Playfair Display";
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url("../fonts/playfair-display/playfair-display-latin-700-italic.woff2") format("woff2"), url("../fonts/playfair-display/playfair-display-all-700-italic.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url("../fonts/playfair-display/playfair-display-cyrillic-900-normal.woff2") format("woff2"), url("../fonts/playfair-display/playfair-display-all-900-normal.woff") format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url("../fonts/playfair-display/playfair-display-vietnamese-900-normal.woff2") format("woff2"), url("../fonts/playfair-display/playfair-display-all-900-normal.woff") format("woff");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url("../fonts/playfair-display/playfair-display-latin-ext-900-normal.woff2") format("woff2"), url("../fonts/playfair-display/playfair-display-all-900-normal.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url("../fonts/playfair-display/playfair-display-latin-900-normal.woff2") format("woff2"), url("../fonts/playfair-display/playfair-display-all-900-normal.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Playfair Display";
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src: url("../fonts/playfair-display/playfair-display-cyrillic-900-italic.woff2") format("woff2"), url("../fonts/playfair-display/playfair-display-all-900-italic.woff") format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: "Playfair Display";
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src: url("../fonts/playfair-display/playfair-display-vietnamese-900-italic.woff2") format("woff2"), url("../fonts/playfair-display/playfair-display-all-900-italic.woff") format("woff");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Playfair Display";
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src: url("../fonts/playfair-display/playfair-display-latin-ext-900-italic.woff2") format("woff2"), url("../fonts/playfair-display/playfair-display-all-900-italic.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Playfair Display";
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src: url("../fonts/playfair-display/playfair-display-latin-900-italic.woff2") format("woff2"), url("../fonts/playfair-display/playfair-display-all-900-italic.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/*# sourceMappingURL=fonts.css.map */